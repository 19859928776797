import clsx from "clsx";
import { gaClickTrack } from "helpers/ga_event_tracking";
import * as React from "react";
import SVG from "react-inlinesvg";

import DownArrow from "@assets/icons/down.svg";
import { Attendance as AttendanceType } from "@graphql/client_app/types";

import {
  getBreakTimes,
  getDailyTotal,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { Checkbox } from "@/ui/Checkbox";

interface SubmittedAttendancesGroupListItemProps {
  attendance: AttendanceType;
  attendancesCount: number;
  stackOrder: number;
  isExpanded: boolean;
  isMobile: boolean;
  onExpandList: (event: React.MouseEvent) => void;
  onAttendanceToggle: (attendanceId: string) => void;
  checkedAttendances: string[];
}

export const SubmittedAttendancesGroupListItem = React.forwardRef(
  (
    {
      attendance,
      attendancesCount,
      stackOrder,
      isExpanded,
      isMobile,
      onExpandList,
      onAttendanceToggle,
      checkedAttendances,
    }: SubmittedAttendancesGroupListItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [showAdditionalNotes, setShowAdditionalNotes] = React.useState(false);
    const cellClassNames = "col-span-full md:col-span-2";
    const style = {
      transform: isExpanded
        ? "translateY(0%)"
        : `translateY(${(stackOrder - 1) * -100}%)`,
    };

    function gaEventTracking(clickText: string) {
      gaClickTrack({
        click_text: clickText,
      });
    }

    function handleViewAllShifts(event: React.MouseEvent): void {
      onExpandList(event);
      gaEventTracking("VIEW ALL SHIFTS");
    }

    function handleToggleAdditionalNotes(): void {
      setShowAdditionalNotes(!showAdditionalNotes);
      gaEventTracking("ADDITIONAL NOTES");
    }

    function renderActionCellContent(
      attendancesCount: number,
      isExpanded: boolean
    ): JSX.Element {
      const additionalNotesButton = (
        <button
          className={clsx("flex font-semibold text-primary-900", {
            "text-neutral-500": !attendance.additionalNotes,
          })}
          disabled={!attendance.additionalNotes}
          onClick={
            attendance.additionalNotes ? handleToggleAdditionalNotes : undefined
          }
        >
          ADDITIONAL NOTES
          <SVG
            src={DownArrow}
            className='icon fill-current mr-4 cursor-pointer'
          />
        </button>
      );

      if (attendancesCount === 1) {
        return additionalNotesButton;
      }

      if (isExpanded) {
        return additionalNotesButton;
      } else {
        return (
          <button
            className='flex font-semibold text-primary-900'
            onClick={handleViewAllShifts}
          >
            <span className='flex items-center'>
              VIEW ALL SHIFTS
              <SVG
                src={DownArrow}
                className='icon fill-current mr-4 cursor-pointer'
              />
            </span>
          </button>
        );
      }
    }

    return (
      <div
        ref={ref}
        style={style}
        className={clsx(
          "border-neutral-300 border-2 rounded-xl bg-shade-50",
          "text-neutral-700 text-left font-semibold md:text-center",
          "p-2",
          "relative origin-top-left transition-all",
          {
            "mb-2 md:mb-4 last:mb-0": isExpanded,
            "z-50": stackOrder === 1,
            "opacity-0 z-[-999]": stackOrder > 1 && !isExpanded,
            "opacity-1": isExpanded,
          }
        )}
      >
        <div className='h-full grid grid-cols-11 items-center p-4 gap-2 md:p-6 xl:gap-x-2.5 2xl:gap-x-6'>
          <div className='col-span-full justify-self-end md:justify-self-auto -mt-11 md:mt-0 md:col-span-1'>
            {isExpanded && (
              <Checkbox
                checked={checkedAttendances.includes(attendance.id)}
                onChange={() => {
                  onAttendanceToggle(attendance.id);
                }}
              />
            )}
          </div>
          <div className='col-span-full text-button-md text-primary-900 font-bold order-first md:text-neutral-700 md:font-semibold md:col-span-2 md:order-none'>
            {attendance.workedOnDate}
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Hours: </span>
            <span className='font-normal md:font-semibold'>
              {`${attendance.startTime} - ${attendance.finishTime}`}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Breaks: </span>
            <span className='font-normal md:font-semibold'>
              {getBreakTimes(attendance.breaks).map((breakTime, index) => (
                <div key={index}>{breakTime}</div>
              ))}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Daily Total: </span>
            <span className='font-normal md:font-semibold'>
              {getDailyTotal(attendance.duration, attendance.breaks)}
            </span>
          </div>
          {isMobile && !!attendance.additionalNotes && (
            <div className={cellClassNames}>
              <span className='md:hidden'>Additional Notes: </span>
              <span className='font-normal md:font-semibold'>
                {attendance.additionalNotes}
              </span>
            </div>
          )}
          <div className='col-span-2 hidden md:block'>
            {renderActionCellContent(attendancesCount, isExpanded)}
          </div>
          {!isMobile && showAdditionalNotes && (
            <div className='col-span-full text-left md:mt-1.5 md:px-7'>
              <hr className='border-neutral-100 hidden md:block' />
              <span className='p2 text-neutral-800 font-normal md:mt-3.5 md:inline-block md:font-semibold'>
                {attendance.additionalNotes}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
