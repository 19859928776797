import type { Instance as FlatpickrInstance } from "flatpickr/dist/types/instance";
import type { Options as FlatpickrOptions } from "flatpickr/dist/types/options";
import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  static targets = ["dateRange", "startAt", "finishAt"];
  declare dateRangeTarget: HTMLInputElement;
  declare startAtTarget: HTMLInputElement;
  declare finishAtTarget: HTMLInputElement;

  declare config: FlatpickrOptions;
  dateFormat = "d/m/Y";

  connect(): void {
    this.config = {
      mode: "range",
      dateFormat: this.dateFormat,
    };
    super.connect();
  }

  change(
    selectedDates: Date[],
    dateString: string,
    instance: FlatpickrInstance
  ): void {
    if (selectedDates.length > 1) {
      const startAt = instance.formatDate(selectedDates[0], this.dateFormat);
      const finishAt = instance.formatDate(selectedDates[1], this.dateFormat);

      this.dateRangeTarget.value = dateString;
      this.dispatchCustomEvent(startAt, finishAt);
    }
  }

  reload(): void {
    if (!this.dateRangeTarget.value) {
      const startAt = null;
      const finishAt = null;

      this.dispatchCustomEvent(startAt, finishAt);
    }
  }

  dispatchCustomEvent(startAt: string, finishAt: string): void {
    const customEvent = new CustomEvent("flatpickr-date-range-changed");

    this.startAtTarget.value = startAt;
    this.finishAtTarget.value = finishAt;
    window.dispatchEvent(customEvent);
  }
}
