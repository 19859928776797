import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { useEffect } from "react";
import SVG from "react-inlinesvg";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import approvedImageUrl from "@assets/illustrations/timesheet_approved_image.svg";

import { ATTENDANCES_APPROVAL_PATH } from "@/client_app/config";

import { COMPONENT_NAME as ApproveAttendancesPreviewComponentName } from "./ApproveAttendancesPreview";
import { FullLayoutModal } from "./FullLayoutModal";

import { hotjarNoop } from "@/hotjar";

window.executeHotjarEvent = window.executeHotjarEvent || hotjarNoop;

window.terminologyEmployee = window.terminologyEmployee || "Weployees";

const ApprovalSuccess = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const prefix =
    searchParams.get("prefix") ||
    i18next.t("clientApp.yourWeployees", {
      terminologyEmployee: window.terminologyEmployee,
    });
  const navigate = useNavigate();

  type locationStateType = {
    from: string;
  };

  const location = useLocation();

  const handleBack = () => {
    navigate(tenantPath(ATTENDANCES_APPROVAL_PATH), {
      state: { from: ApprovalSuccess.name },
    });
  };

  const handleFeedBack = (e) => {
    window.executeHotjarEvent(e);
  };

  useEffect(() => {
    if (!location.state) return handleBack();
    const { from } = location.state as locationStateType;

    if (from !== ApproveAttendancesPreviewComponentName) {
      handleBack();
    }
  });

  return (
    <FullLayoutModal
      isOpen={true}
      title='Back to Timesheets'
      closeModal={handleBack}
    >
      <div className='flex flex-col items-center mt-3.5 md:mt-10'>
        <h1 className='h4-sm font-bold text-secondary-700 md:h1'>
          Timesheet Approved!
        </h1>
        <h4 className='p1 mt-2 mb-4 md:mb-6 md:mt-6 text-neutral-900 md:h4'>
          {prefix} will be paid within 10 business days
        </h4>

        <div className='flex justify-center mt-4'>
          <div className='flex justify-center flex-col'>
            <p className='mb-4 text-sm font-semibold md:text-2xl'>
              How was approving timesheets?
            </p>
            <button
              name='button'
              type='button'
              className='btn-primary mx-auto py-2 px-4 text-sm md:text-base font-bold hj-target'
              data-hj-event='survey_feedback_weployer_timesheets'
              onClick={handleFeedBack}
            >
              Send feedback
            </button>
          </div>
        </div>

        <div className='w-4/5'>
          <SVG className='w-full' src={approvedImageUrl} />
        </div>
      </div>
    </FullLayoutModal>
  );
};

export { ApprovalSuccess };
